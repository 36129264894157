import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

class PaymentPreview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            selectedAdvancePayments: [],
            columns: [
                //{
                //    dataField: '_id.id',
                //    text: 'ID',
                //    sort: true
                //},
                {
                    dataField: '_id.firstname',
                    text: 'Ime',
                    sort: true
                },
                {
                    dataField: '_id.lastname',
                    text: 'Priimek',
                    sort: true
                },
                {
                    dataField: 'hoursSum',
                    text: 'Vsota ur',
                    sort: true
                },
                {
                    dataField: 'payment',
                    text: 'Plača',
                    sort: true,
                    formatter: (cellContent, row) => {
                        return (
                            <strong>{cellContent} ({row._id.basicpayment})</strong>
                        );
                    }
                }, {
                    dataField: 'df',
                    isDummyField: true,
                    text: 'Akontacije',
                    sort: false,
                    formatter: (cellContent, row) => {
                        if (row._id.advancePayments && row._id.advancePayments.length > 0)
                            return <span role="img" aria-label="ok">✔️</span>;
                        else 
                            return <span role="img" aria-label="delete">❌</span>;
                    }
                }
            ],
            columnsAdvancePayments: [
                //{
                //    dataField: '_id',
                //    text: 'ID',
                //    sort: false
                //},
                {
                    dataField: 'date',
                    text: 'Datum',
                    sort: false,
                    formatter: (cell) => {
                        if (cell == null)
                            return

                        let dateObj = new Date(cell);
                        var datestring = ("0" + dateObj.getDate()).slice(-2) + "." + ("0" + (dateObj.getMonth() + 1)).slice(-2) + "." + dateObj.getFullYear();
                        return datestring;
                    }
                },
                {
                    dataField: 'amount',
                    text: 'Znesek',
                    sort: false
                },
                {
                    dataField: 'note',
                    text: 'Opomba',
                    sort: false
                }
            ]

        };
    }

    componentDidMount() {
       //pridobi mesec izplačila in željene uporabnike
        if (!this.props.selectedEmployees || !this.props.dateOfPayment) {
            console.log("Missing parameter (selectedEmployees or dateOfPayment)!")
            return;
        }

        let data = {
            selectedEmployees : this.props.selectedEmployees,
            dateOfPayment : this.props.dateOfPayment
        };

        axios.post('/employee/payment/', "data=" + JSON.stringify(data))
            .then(response => {
                console.log(response.data)
                this.setState({ data: response.data })
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    componentWillUnmount() {
        //pošljemo podatke do parent-a
        this.props.parentCallback(this.state.data, this.state.selectedAdvancePayments);
    }


    //fukcija preračuna znesek plače glede na izbrane akontacije
    updatePayment = (id, amount) => {
        //poiščemo index v katerem zapisu se nahaja akontacija, ki smo jo obkljukali
        var index = -1;
        var oldPayment = 0;
        this.state.data.forEach(function (item, ind) {
            var found = item._id.advancePayments.find(x => x._id === id);
            if (found) {
                index = ind;
                oldPayment = item.payment;
            }
        });

        //dolgi način:
        ////1. Make a shallow copy of the items
        //let data = [...this.state.data];
        ////2. Make a shallow copy of the item you want to mutate
        //let firstItem = { ...data[index] };
        //// 3. Modify/replace the property you're intested in
        //firstItem.payment -= amount;
        //// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        //data[index] = firstItem;
        //// 5. Set the state to our new copy
        //this.setState({ data });

        //kratek način
        this.setState(({ data }) => ({
            data: [
                ...data.slice(0, index),
                {
                    ...data[index],
                    payment: oldPayment - amount,
                },
                ...data.slice(index + 1)
            ]
        }));
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {

            this.updatePayment(row._id, row.amount);

            this.setState(() => ({
                selectedAdvancePayments: [...this.state.selectedAdvancePayments, row._id]
            }));
        } else {

            this.updatePayment(row._id, -row.amount);

            this.setState(() => ({
                selectedAdvancePayments: this.state.selectedAdvancePayments.filter(x => x !== row._id)
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r._id);
        if (isSelect) {
            this.setState(() => ({
                selectedAdvancePayments: ids
            }));
        } else {
            this.setState(() => ({
                selectedAdvancePayments: []
            }));
        }
    }

    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            style: { backgroundColor: '#adcdff' },
            selected: this.state.selectedAdvancePayments,
            onSelect: this.handleOnSelect,
            hideSelectAll : true
        };

        //expand row -> prikaz tabele za obračun akontacij
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => (
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            <h5>Akontacije</h5>

                            <BootstrapTable
                                bootstrap4={true}
                                keyField='_id'
                                data={row._id.advancePayments.filter(a => a.alreadyPayed === false)}
                                columns={this.state.columnsAdvancePayments}
                                headerClasses="thead-light"
                                classes="table-sm"
                                selectRow={selectRow}
                            />
                        </div>
                        <div className="col-sm">
                        </div>
                    </div>
                </div>

            )
        };

        return (
            <div className="container" style={{ marginTop: 20 }}>
                <ToolkitProvider
                    keyField="_id.id"
                    data={this.state.data}
                    columns={this.state.columns}>
                    {
                        props => (
                            <div>

                                <BootstrapTable
                                    bootstrap4={true}
                                    pagination={paginationFactory()}
                                    headerClasses="thead-light"
                                    expandRow={expandRow}
                                    {
                                    ...props.baseProps
                                    }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );

    }
}

export default connect(
)(PaymentPreview);