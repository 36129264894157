import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import PaymentPreview from "./PaymentPreview";

const companies = [
    { vatnumber: 'SK2024040909', name: "International Solutions s.r.o." },
    { vatnumber: 'SK2120297707', name: "Int. Solutions s.r.o." }];

class PaymentProcess extends Component {

    constructor(props) {
        super(props);

        var tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        this.state = {
            currentStep: 1,
            dateOfPayment: '',
            selectedEmployees: '',
            selectedAdvancePayments: '',
            paymentData: '',
            payDate: tomorrow.toISOString().substring(0, 10),
            selectedCompany: companies[0].vatnumber
        }
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    //handleSubmit = event => {
    //    event.preventDefault()
    //    const { dateOfPayment, username, password } = this.state
    //    alert(dateOfPayment)
    //}

    _next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 2 ? 3 : currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1 && currentStep !== 3) {
            return (
                <button
                    className="btn btn-secondary"
                    type="button" onClick={this._prev}>
                    Nazaj
                </button>
            )
        }
        return null;
    }

    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 3) {
            return (
                <button
                    className="btn btn-primary float-right"
                    type="button" onClick={this._next}>
                    Naprej
                </button>
            )
        }
        return null;
    }

    componentDidMount() {
        //this.state.selectedEmployees = this.props.location.selectedEmployees;   //pridobimo seznam izbranih iz EmployeeListPagination
        this.setState({
            selectedEmployees: this.props.location.state.selectedEmployees
        });

    }

    //preko tega callback-a dobimo podatke iz PaymentPreview
    callbackPaymentPreview = (paymentData, selectedAdvancePayments) => {

        this.setState({
            selectedAdvancePayments: selectedAdvancePayments,   //izbrane akontacije
            paymentData: paymentData    //izračuni plač
        });
    }

    callbackGetSepaXML = () => {

        //shrani akontacije
        if (this.state.selectedAdvancePayments.length > 0) {
            axios.post('/employee/updateAdvance/', "data=" + JSON.stringify(this.state.selectedAdvancePayments))
                .then(response => {
                    console.log(response.data)
                    //this.setState({ data: response.data })
                }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }

        //shrani v tabelo plač in generiraj SEPA XML
        let xhr = new XMLHttpRequest()
        xhr.open('POST', axios.defaults.baseURL + "/sepagen/", true)
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
        xhr.responseType = 'arraybuffer'

        xhr.onload = function (e) {
            if (this.status === 200) {
                let blob = new Blob([this.response], { type: "application/xml" })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'export.xml'
                link.click()
            }
        };

        let data = {
            paymentData : this.state.paymentData,
            dateOfPayment: this.state.dateOfPayment,
            payDate: this.state.payDate,
            selectedCompany: this.state.selectedCompany
        };

        xhr.send("data=" + JSON.stringify(data));

    }
    
    render() {

        return (
            <div className="container" style={{ marginTop: 20 }}>
            <React.Fragment>
                    <h3>Obračun plač</h3>
                    <hr/>
                    <h5>Korak {this.state.currentStep} </h5>

                    <Step1
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        dateOfPayment={this.state.dateOfPayment}
                    />
                    <Step2
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        selectedEmployees={this.state.selectedEmployees}
                        dateOfPayment={this.state.dateOfPayment}
                        callbackPaymentPreview={this.callbackPaymentPreview}
                    />
                    <Step3
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        callbackGetSepaXML={this.callbackGetSepaXML}
                        payDate={this.state.payDate}
                        selectedCompany={this.state.selectedCompany}

                    />

                    <hr />

                    {this.previousButton()}
                    {this.nextButton()}
   
                </React.Fragment>
            </div>
        );

    }
}

//vnos datuma izplačila
function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }
    return (
        <div className="form-group row">
            <label htmlFor="dateOfPayment" className="col-sm-2 col-form-label">Mesec obračuna</label>
            <div className="col-sm-4">
                <input id="dateOfPayment" type="month" className="form-control" placeholder="Mesec obračuna" name="dateOfPayment" value={props.dateOfPayment || ''} onChange={props.handleChange} />
            </div>
        </div>
    );
}



//prikaz tabele z izplačili
function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }

    return (
        <PaymentPreview parentCallback={props.callbackPaymentPreview}
            dateOfPayment={props.dateOfPayment}
            selectedEmployees={props.selectedEmployees}
            selectedAdvancePayments={props.selectedAdvancePayments} />
    );
}

//zaključek priprave izplačil in generiranje XML
function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    }

    return (
        <React.Fragment>

            <div className="form-group row">
                <label htmlFor="inputPaymentDate" className="col-sm-2 col-form-label">Datum izplačila</label>
                <div className="col-sm-4">
                    <input type="date" className="form-control" id="inputPaymentDate" placeholder="Datum izplačila" name="payDate" value={props.payDate || ''} onChange={props.handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Podjetje</label>
                <div className="col-sm-4">
                    <select id="inputCompany" className="form-control" name="selectedCompany" value={props.selectedCompany || ''} onChange={props.handleChange}>
                        {
                            companies.map(current => {
                                return <option key={current.vatnumber} value={current.vatnumber}>{current.name}</option>;
                            })
                        }

                    </select>
                </div>
            </div>

            <button className="btn btn-success btn-block" onClick={props.callbackGetSepaXML}>Pridobi SEPA XML</button>

        </React.Fragment>

        //<React.Fragment>
        //    <div className="form-group">
        //        <label htmlFor="password">Password</label>
        //        <input
        //            className="form-control"
        //            id="password"
        //            name="password"
        //            type="password"
        //            placeholder="Enter password"
        //            value={props.password}
        //            onChange={props.handleChange}
        //        />
        //    </div>
        //    <button className="btn btn-success btn-block">Sign up</button>
        //</React.Fragment>
    );
}

export default connect(
)(PaymentProcess);


