import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import axios from 'axios';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

//const monthNames = ["Januar", "Februar", "Marec", "April", "Maj", "Junij",
//    "Julij", "Avgust", "September", "Oktober", "November", "December"
//];

class EmployeeAdvancePayments extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addAdvancePayment = this.addAdvancePayment.bind(this);

        this.state = {
            advancePayments: [],
            selected: [],
            newData: {
                date: '',
                amount: '',
                note: ''
            },
            columns: [
                //{
                //    dataField: '_id',
                //    text: 'ID',
                //    sort: true
                //    },
                {
                    dataField: 'date',
                    text: 'Datum',
                    sort: true,
                    formatter: (cell) => {
                        if (cell == null)
                            return

                        let dateObj = new Date(cell);
                        var datestring = ("0" + dateObj.getDate()).slice(-2) + "." + ("0" + (dateObj.getMonth() + 1)).slice(-2) + "." + dateObj.getFullYear();
                        return datestring;
                    }
                },
                {
                    dataField: 'amount',
                    text: 'Znesek',
                    sort: true
                }, {
                    dataField: 'note',
                    text: 'Opomba',
                    sort: true
                }, {
                    dataField: 'df1',
                    isDummyField: true,
                    text: 'Akcije',
                    formatter: (cellContent, row) => {
                        return (
                            <button className="btn btn-outline-secondary btn-sm" type="button" onClick={() => this.deleteAdvancePayment(row._id)}>Izbriši</button>
                        );
                    }
                }


            ]
        };
    }
    //
    componentDidMount() {
        axios.get('/employee/getAdvance/' + this.props.match.params.id)
            .then(response => {
                this.setState({ advancePayments: response.data.advancePayments })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    addAdvancePayment(e) {
        e.preventDefault();

        const advancePayment = this.state.newData;

        axios.post('/employee/addAdvance/' + this.props.match.params.id, advancePayment)
            .then(res => {
                console.log(res.data)
                this.setState({ advancePayments: [...this.state.advancePayments, res.data], newData: {} })   //kompakten način dodajanja elementa v array objektov, hkrati počistimo newData
                //this.props.history.push("/zaposleni2");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    deleteAdvancePayment(id) {
        const employeeId = {
            employeeId: this.props.match.params.id
        };

        //pri delete-u se pošiljajo parametri na drugačen način
        axios.delete('/employee/deleteAdvance/' + id, { data: employeeId })
            .then(res => {
                console.log(res.data)

                this.setState({
                    advancePayments: this.state.advancePayments.filter(item => item._id !== id)      //brisanje objekta iz array-a
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ newData: { ...this.state.newData, [name]: value } });
    }

    render() {
        const rowClasses = (row, rowIndex) => {
            let classes = null;

            if (row.alreadyPayed) {
                classes = 'alreadyPayed';
            }

            return classes;
        };

        return (
            <div className="container" style={{ marginTop: 20 }}>
                <h3>Akontacije zaposlenega - {this.props.location.currentEmployee || ''}</h3>
                <br />

                <form onSubmit={this.addAdvancePayment}>
                    <div className="input-group mb-3">
                        <input type="date" className="form-control" placeholder="Datum" required name="date" value={this.state.newData.date || ''} onChange={this.handleInputChange} />
                        <input type="text" className="form-control" placeholder="Znesek" required name="amount" value={this.state.newData.amount || ''} onChange={this.handleInputChange} />
                        <input type="text" className="form-control" placeholder="Opomba" required name="note" value={this.state.newData.note || ''} onChange={this.handleInputChange} />

                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="submit" >Dodaj</button>
                        </div>
                    </div>
                </form>

                <ToolkitProvider
                    keyField="_id"
                    data={this.state.advancePayments}
                    columns={this.state.columns}>
                    {
                        props => (
                            <div>

                                <BootstrapTable
                                    bootstrap4={true}
                                    pagination={paginationFactory()}
                                    headerClasses="thead-light"
                                    rowClasses={rowClasses}
                                    {
                                    ...props.baseProps
                                    }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
    }
}

export default connect(
)(EmployeeAdvancePayments);


