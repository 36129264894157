import { combineReducers } from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import dataReducer from "./dataReducers";
import loadReducer from "./loadReducers";

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    data: dataReducer,
    load: loadReducer
});