import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';

const companies = [
    { vatnumber: 'SK2024040909', name: "International Solutions s.r.o." },
    { vatnumber: 'SK2120297707', name: "Int. Solutions s.r.o." }];

class SepaPaymentParams extends Component {

    constructor(props) {
        super(props);

        var tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        this.state = {
            payDate: tomorrow.toISOString().substring(0, 10),
            selectedCompany: companies[0].vatnumber,
            selectedSepas: []
        }
    }

    componentDidMount() {
        if(this.props.location.state)
            this.setState({ selectedSepas: this.props.location.state.selectedSepas });  
    }

    handleInputChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        //generiraj SEPA XML
        let xhr = new XMLHttpRequest()
        xhr.open('POST', axios.defaults.baseURL + "/sepagen/list", true)
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
        xhr.responseType = 'arraybuffer'

        xhr.onload = function (e) {
            if (this.status === 200) {
                let blob = new Blob([this.response], { type: "application/xml" })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'export.xml'
                link.click()
            }
        };

        let data = {
            paymentData: this.state.selectedSepas,
            payDate: this.state.payDate,
            selectedCompany: this.state.selectedCompany
        };

        xhr.send("data=" + JSON.stringify(data));
    }

    handleBtnCancelClick = () => {
        this.props.history.push("/sepa")
    }

    render() {
        const { payDate, selectedCompany} = this.state
        return (
            <div>
                <br />
                <h3>Vnesite podatke</h3>
                <br />

                <form onSubmit={this.handleSubmit}>

                    <div className="form-group row">
                        <label htmlFor="inputPaymentDate" className="col-sm-2 col-form-label">Datum izplačila</label>
                        <div className="col-sm-4">
                            <input type="date" className="form-control" id="inputPaymentDate" placeholder="Datum izplačila" name="payDate" value={payDate || ''} onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Podjetje</label>
                        <div className="col-sm-4">
                            <select id="inputCompany" className="form-control" name="selectedCompany" value={selectedCompany || ''} onChange={this.handleInputChange}>
                                {
                                    companies.map(current => {
                                        return <option key={current.vatnumber} value={current.vatnumber}>{current.name}</option>;
                                    })
                                }

                            </select>
                        </div>
                    </div>

                    <hr />

                    <div className="form-group">
                        <button className="btn btn-success btn-block">Pridobi SEPA XML</button>
                    </div>
                </form>

            </div>
        );

    }
}

export default connect(
)(SepaPaymentParams);


