import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';

class EmployeeEdit extends Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBtnCancelClick = this.handleBtnCancelClick.bind(this);

        this.state = {
            editMode: true,
            firstname: "",
            lastname: "",
            address: "",
            city: "",
            dateofbirth: "",
            nationality: "Slovensko",
            trrnumber: "",
            insurancenumber: "",
            basicpayment: "",
            documentnumber: "",
            bicnumber: ""
        }

        if (this.props.match.params.id && this.props.match.params.id === "new") {
            this.state.editMode = false;
        }
    }

    handleInputChange(event) {
        const { name, value } = event.target
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        const employee = this.state;

        console.log(employee);

        if (this.state.editMode) {
            axios.post('/employee/edit/' + this.props.match.params.id, employee)
                .then(res => {
                    console.log(res.data)
                    this.props.history.push("/zaposleni");
                }
                )
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            axios.post('/employee/add/', employee)
                .then(res => {
                    console.log(res.data)
                    this.props.history.push("/zaposleni");
                }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    handleBtnCancelClick() {
        this.props.history.push("/zaposleni")
    }

    componentDidMount() {
        if (this.state.editMode) {
            axios.get('/employee/' + this.props.match.params.id)
                .then(response => {
                    this.setState({
                        firstname: response.data.firstname,
                        lastname: response.data.lastname,
                        address: response.data.address,
                        city: response.data.city,
                        dateofbirth: new Date(response.data.dateofbirth).toISOString().slice(0, 10),
                        nationality: response.data.nationality,
                        trrnumber: response.data.trrnumber,
                        insurancenumber: response.data.insurancenumber,
                        basicpayment: response.data.basicpayment,
                        documentnumber: response.data.documentnumber,
                        bicnumber: response.data.bicnumber
                    })
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

    }

    render() {
        const { editMode, firstname, lastname, address, city, dateofbirth, nationality, trrnumber, insurancenumber, bicnumber, documentnumber, basicpayment } = this.state
        return (
            <div>
                <br />

                <h3>{editMode ? 'Urejanje zaposlenega - ' +  (this.props.location.currentEmployee || '')  : 'Dodajanje zaposlenega'}</h3>
                <br />

                <form onSubmit={this.handleSubmit} autocomplete="off">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor ="inputFirstname">Ime</label>
                            <input name="firstname" value={firstname || ''} required type="text" className="form-control" id="inputFirstname" placeholder="Ime" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor ="inputLastname">Priimek</label>
                            <input name="lastname" value={lastname || ''} required type="text" className="form-control" id="inputLastname" placeholder="Priimek" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-8">
                            <label htmlFor="inputAddress">Naslov</label>
                            <input name="address" value={address || ''} required type="text" className="form-control" id="inputAddress" placeholder="Naslov" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputCity">Pošta</label>
                            <input name="city" value={city || ''} required type="text" className="form-control" id="inputCity" placeholder="Pošta" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="inputDateofbirth">Datum rojstva</label>
                            <input name="dateofbirth" type="date" required className="form-control" id="inputDateofbirth" value={dateofbirth || ''} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputNationality">Državljanstvo</label>
                            <select name="nationality" value={nationality || ''} id="inputNationality" className="form-control" onChange={this.handleInputChange}>
                                <option value="Slovensko">Slovensko</option>
                                <option value="Hrvaško">Hrvaško</option>
                                <option value="Srbsko">Srbsko</option>
                                <option value="BIH">BIH</option>
                                <option value="Madžarsko">Madžarsko</option>
                                <option value="Slovaško">Slovaško</option>
                            </select>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputBasicpayment">Osnovna plača</label>
                            <input name="basicpayment" value={basicpayment || ''} required type="text" className="form-control" id="inputBasicpayment" placeholder="Osnovna plača" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputTRRNumber">TRR številka</label>
                            <input name="trrnumber" value={trrnumber || ''} required type="text" className="form-control" id="inputTRRNumber" placeholder="TRR številka" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputBICNumber">BIC številka</label>
                            <input name="bicnumber" value={bicnumber || ''} required type="text" className="form-control" id="inputBICNumber" placeholder="BIC številka" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputInsuranceNumber">Številka zavarovanja</label>
                            <input name="insurancenumber" value={insurancenumber || ''} type="text" className="form-control" id="inputInsuranceNumber" placeholder="Številka zavarovanja" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputDocumentnumber">Številka dokumenta</label>
                            <input name="documentnumber" value={documentnumber || ''} type="text" className="form-control" id="inputDocumentnumber" placeholder="Številka dokumenta" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-space">Shrani</button>
                        <button onClick={this.handleBtnCancelClick} className="btn btn-primary">Prekliči</button> 
                    </div>
                </form>
            </div>);

    }
}

//<div className="form-group">
//    <div className="form-check">
//        <input className="form-check-input" type="checkbox" id="gridCheck" />
//        <label className="form-check-label" htmlFor="gridCheck">
//            Check me out
//                            </label>
//    </div>
//</div>

//<div className="form-row">
//    <div className="form-group col-md-6">
//        <label htmlFor="inputCity">City</label>
//        <input type="text" className="form-control" id="inputCity" />
//    </div>
//    <div className="form-group col-md-4">
//        <label htmlFor="inputState">State</label>
//        <select id="inputState" className="form-control">
//            <option>Choose...</option>
//            <option>...</option>
//        </select>
//    </div>
//    <div className="form-group col-md-2">
//        <label htmlFor="inputZip">Zip</label>
//        <input type="text" className="form-control" id="inputZip" />
//    </div>
//</div>

export default connect(
)(EmployeeEdit);


