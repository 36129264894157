import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class EmployeeWorkingHoursImport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            file: '',
            filePlacilniNalogi: '',
            filePlacilniNalogiVUB: '',
            labelPlacilniNalog: 'Izberite xlsx datoteko',
            labelPlacilniNalogVUB: 'Izberite xlsx datoteko',
            modal: false,
            modalMessage: ''
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.state.file)
            return;

        let res = await this.uploadFile(this.state.file, 'zaposleni');
    }

    handleSubmitNalogi = async (e) => {
        e.preventDefault();

        if (!this.state.filePlacilniNalogi)
            return;

        let res = await this.uploadFile(this.state.filePlacilniNalogi, 'placilniNalogi');
    }

    handleSubmitNalogiVUB = async (e) => {
        e.preventDefault();

        if (!this.state.filePlacilniNalogiVUB)
            return;

        let res = await this.uploadFile(this.state.filePlacilniNalogiVUB, 'placilniNalogiVUB');
    }

    onChangeFile = (e) => {
        if (!e.target.files || !e.target.files[0])
            return;

        this.setState({ file: e.target.files[0] });
    }

    onChangeFilePlacilniNalogi = (e) => {
        if (!e.target.files || !e.target.files[0])
            return;

        this.setState({
            filePlacilniNalogi: e.target.files[0],
            labelPlacilniNalog: e.target.files[0].name
        })
    }

    onChangeFilePlacilniNalogiVUB = (e) => {
        if (!e.target.files || !e.target.files[0])
            return;

        this.setState({
            filePlacilniNalogiVUB: e.target.files[0],
            labelPlacilniNalogVUB: e.target.files[0].name
        })
    }

    buttonClick = () => {

    }

    uploadFile = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);

        return await axios.post("/upload", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then(res => {
            this.setState({ modalMessage: res.data.err_desc })
            this.toggle()  
        }).catch((error) => {
            console.log(error);
        });
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    render() {
        const { labelPlacilniNalog, labelPlacilniNalogVUB, modal, modalMessage } = this.state

        return (
            <div>
                <br />
                <h3>Uvoz ur zaposlenih iz Excel datoteke</h3>
                <br />

                <form id="uploadFormZaposleni" method="post" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" name="file" onChange={this.onChangeFile} />
                            <label className="custom-file-label" for="customFile">Izberite xlsx datoteko</label>
                        </div>  
                    </div>
                    <hr />
                    <div className="form-row">
                        <button type="submit" className="btn btn-primary">Uvozi</button>
                    </div>

                </form>

                <br />
                <h3>Uvoz plačilnih nalogov (banka TATRA)</h3>
                <br />

                <form id="uploadFormNalogi" method="post" onSubmit={this.handleSubmitNalogi}>
                    <div className="form-row">
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" name="file" onChange={this.onChangeFilePlacilniNalogi} />
                            <label className="custom-file-label" for="customFile">{labelPlacilniNalog}</label>
                        </div>
                    </div>
                    <hr />
                    <div className="form-row">
                        <button type="submit" className="btn btn-primary">Uvozi</button>
                    </div>
                </form>

                <br />
                <h3>Uvoz plačilnih nalogov (banka VUB)</h3>
                <br />

                <form id="uploadFormNalogi2" method="post" onSubmit={this.handleSubmitNalogiVUB}>
                    <div className="form-row">
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" name="file" onChange={this.onChangeFilePlacilniNalogiVUB} />
                            <label className="custom-file-label" for="customFile">{labelPlacilniNalogVUB}</label>
                        </div>
                    </div>
                    <hr />
                    <div className="form-row">
                        <button type="submit" className="btn btn-primary">Uvozi</button>
                    </div>
                </form>

                <Modal isOpen={modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Obvestilo</ModalHeader>
                    <ModalBody>
                        {modalMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>
                            Zapri
                        </Button>
                    </ModalFooter>
                </Modal>


           </div>
        );

    }
}

export default connect(
)(EmployeeWorkingHoursImport);


