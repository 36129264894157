import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';

const { SearchBar } = Search;

class EmployeeListPagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            selected: [],
            columns: [
            //{
            //    dataField: '_id',
            //    text: 'ID',
            //    sort: true
            //    },
            {
                dataField: 'firstname',
                text: 'Ime',
                sort: true
            },
            {
                dataField: 'lastname',
                text: 'Priimek',
                sort: true
            }, {
                dataField: 'nationality',
                text: 'Državljanstvo',
                sort: true
            },
            //{
            //    dataField: 'company',
            //    text: 'Podjetje',
            //    sort: true
            // },
                {
                dataField: 'df1',
                isDummyField: true,
                text: 'Akcije',
                formatter: (cellContent, row) => {
                    //if (row.inStock) {
                    if (true) {
                        return (
                            <nav>
                                <Link to={{
                                    pathname: "/zaposleni/edit/" + row._id,
                                    currentEmployee: row.firstname + " " + row.lastname
                                }}><button className="btn btn-outline-secondary btn-sm" type="button">Uredi</button></Link>
                                <Link to={{
                                    pathname : "/zaposleni/ure/" + row._id,
                                    currentEmployee: row.firstname + " " + row.lastname
                                    }} ><button className="btn btn-outline-secondary btn-sm" type="button">Ure</button></Link>
                                <Link to={{
                                    pathname: "/zaposleni/akontacije/" + row._id,
                                    currentEmployee: row.firstname + " " + row.lastname
                                }}><button className="btn btn-outline-secondary btn-sm" type="button">Akontacije</button></Link>
                            </nav>
                        );
                    }
                    return (
                        <h5>
                            <span className="label label-danger"> Backordered</span>
                        </h5>
                    );
                }
            }


            ]
        };
    }

    componentDidMount() {
        axios.get('/employee/')
            .then(response => {
                this.setState({ employees: response.data })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleBtnObracunPlacClick = () => {
        let selection = this.state.selected;

        if (selection.length === 0) {
            alert("Izberite vsaj enega zaposlenega!");
            return true;
        }

        this.props.history.push("/obracunplac", { selectedEmployees: selection });
    }

    handleBtnPorociloClick = () => {
        let selection = this.state.selected;

        if (selection.length === 0) {
            alert("Izberite vsaj enega zaposlenega!");
            return;
        }

        this.props.history.push("/report", { selectedEmployees: selection });


        //let xhr = new XMLHttpRequest()
        //xhr.open('POST', axios.defaults.baseURL + "/reports/", true)
        //xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
        //xhr.responseType = 'arraybuffer'

        //xhr.onload = function (e) {
        //    if (this.status === 200) {
        //        let blob = new Blob([this.response], { type: "application/pdf" })
        //        let link = document.createElement('a')
        //        link.href = window.URL.createObjectURL(blob)
        //        link.download = 'Results.pdf'
        //        link.click()
        //    }
        //};

        //xhr.send("data=" + JSON.stringify(selection));
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected : [...this.state.selected, row._id]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row._id)
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r._id);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
        }
    }

    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
            style: { backgroundColor: '#adcdff' }
        };

        return (
            <div className="container" style={{ marginTop: 50 }}>
                <ToolkitProvider
                    bootstrap4={true}
                    keyField="_id"
                    data={this.state.employees}
                    columns={this.state.columns}
                    search >
                    {
                        props => (
                            <div>
                                <div className="row">
                                    <div className="col-sm-9"><SearchBar placeholder="Išči" {...props.searchProps} /></div>
                                    <div className="col-sm-3">
                                        <button onClick={this.handleBtnObracunPlacClick} className="btn btn-primary btn-space btn-space-left">Obračun plač</button>

                                        <div className="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                Poročila
                                                </button>
                                            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                <a onClick={this.handleBtnPorociloClick} className="dropdown-item" href="#">Namensliste</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <BootstrapTable
                                    pagination={paginationFactory()}
                                    selectRow={selectRow}
                                    headerClasses="thead-light"
                                    {   
                                        ...props.baseProps
                                    }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>    
            </div>
        );
    }
}



export default connect(
)(EmployeeListPagination);


