import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';

class SepaPayment extends Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBtnCancelClick = this.handleBtnCancelClick.bind(this);

        this.state = {
            editMode: true,
            iban: "",
            swift: "",
            receivername: "",
            receiveraddress: "",
            receivercity: "",
            receivercountry: "Slovenija",
            paymentdate: "",
            purpuse: "",
            purpusecode: "",
            amount: "",
            currency: "EUR",
            reference: ""
        }

        if (this.props.match.params.id && this.props.match.params.id === "new") {
            this.state.editMode = false;
        }
    }

    handleInputChange(event) {
        const { name, value } = event.target
        this.setState({
            [name]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        const sepa = this.state;

        console.log(sepa);

        if (this.state.editMode) {
            axios.post('/sepagen/edit/' + this.props.match.params.id, sepa)
                .then(res => {
                    console.log(res.data)
                    //this.props.history.push("/zaposleni");
                }
                )
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            axios.post('/sepagen/add/', sepa)
                .then(res => {
                    console.log(res.data)
                    this.props.history.push("/sepa");
                }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    handleBtnCancelClick() {
        this.props.history.push("/sepa")
    }

    componentDidMount() {
        if (this.state.editMode) {
            axios.get('/sepagen/' + this.props.match.params.id)
                .then(response => {
                    this.setState({
                        iban: response.data.iban,
                        swift: response.data.swift,
                        receivername: response.data.receivername,
                        receiveraddress: response.data.receiveraddress,
                        receivercity: response.data.receivercity,
                        receivercountry: response.data.receivercountry,
                        paymentdate: new Date(response.data.paymentdate).toISOString().slice(0, 10),
                        purpuse: response.data.purpuse,
                        purpusecode: response.data.purpusecode,
                        amount: response.data.amount,
                        currency: response.data.currency,
                        reference: response.data.reference
                    })
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

    }

    render() {
        const { editMode, iban, swift, receivername, receiveraddress, receivercity, receivercountry, paymentdate, purpuse, purpusecode, amount, currency, reference } = this.state
        return (
            <div>
                <br />

                <h3>{editMode ? 'Urejanje naloga' : 'Dodaj SEPA nalog'}</h3>
                <br />

                <form onSubmit={this.handleSubmit} autocomplete="off">
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputReceivername">Naziv</label>
                            <input name="receivername" value={receivername || ''} required type="text" className="form-control" id="inputReceivername" placeholder="Naziv" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputReceiveraddress">Naslov</label>
                            <input name="receiveraddress" value={receiveraddress || ''} type="text" className="form-control" id="inputReceiveraddress" placeholder="Naslov" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputLastname">Mesto</label>
                            <input name="receivercity" value={receivercity || ''} type="text" className="form-control" id="inputReceivercity" placeholder="Mesto" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputReceivercountry">Država</label>
                            <select name="receivercountry" value={receivercountry || ''} id="inputReceivercountry" className="form-control" onChange={this.handleInputChange}>
                                <option value="Slovenija">Slovenija</option>
                                <option value="Hrvaška">Hrvaška</option>
                                <option value="Srbija">Srbija</option>
                                <option value="BIH">BIH</option>
                                <option value="Madžarska">Madžarska</option>
                                <option value="Slovaška">Slovaška</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxemburg">Luxemburg</option>
                                <option value="Kosovo">Kosovo</option>
                                <option value="Velika Britanija">Velika Britanija</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputIban">IBAN</label>
                            <input name="iban" value={iban || ''} required type="text" className="form-control" id="inputIban" placeholder="IBAN" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputSwift">SWIFT</label>
                            <input name="swift" value={swift || ''} required type="text" className="form-control" id="inputSwift" placeholder="SWIFT" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputAmount">Znesek</label>
                            <input name="amount" value={amount || ''} required type="text" className="form-control" id="inputAmount" placeholder="Znesek" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputCurrency">Valuta</label>
                            <select name="currency" value={currency || ''} id="inputCurrency" className="form-control" onChange={this.handleInputChange}>
                                <option value="EUR">Euro</option>
                                <option value="HRK">Hrvaška kuna</option>
                                <option value="RSD">Srbski dinar</option>
                                <option value="USD">Ameriški dolar</option>
                                <option value="HUF">Forint</option>
                                <option value="GBP">Britanski funt</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputPurpuse">Namen</label>
                            <input name="purpuse" value={purpuse || ''} required type="text" className="form-control" id="inputPurpuse" placeholder="Namen" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputPurpuseCode">Koda namena</label>
                            <input name="purpusecode" value={purpusecode || ''} type="text" className="form-control" id="inputPurpuseCode" placeholder="Koda namena" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="inputReference">Referenca</label>
                            <input name="reference" value={reference || ''} required type="text" className="form-control" id="inputReference" placeholder="Referenca" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-space">Shrani</button>
                        <button onClick={this.handleBtnCancelClick} className="btn btn-primary">Prekliči</button> 
                    </div>
                </form>
            </div>);

    }
}

export default connect(
)(SepaPayment);


