import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from 'react-redux';
import './App.css';
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import axios from 'axios'
import store from "./store";

import Loader from 'react-loader-spinner'

import "bootstrap/dist/css/bootstrap.min.css"; 

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { loadActions } from './actions/loadActions'
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import EmployeeEdit from "./components/employee/EmployeeEdit"
import EmployeeListPagination from "./components/employee/EmployeeListPagination"
import EmployeeWorkingHours from "./components/employee/EmployeeWorkingHours"
import EmployeeAdvancePayments from "./components/employee/EmployeeAdvancePayments"
import PaymentProcess from "./components/payment/PaymentProcess"
import EmployeeWorkingHoursImport from "./components/employee/EmployeeWorkingHoursImport"
import EmployeeReportParams from "./components/employee/EmployeeReportParams"
import SepaPayment from "./components/payment/SepaPayment"
import SepaPaymentPagination from "./components/payment/SepaPaymentPagination"
import SepaPaymentParams from "./components/payment/SepaPaymentParams"

// Check for token to keep user logged in
if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Redirect to login
        window.location.href = "./login";
        console.log("");
    }
}

class App extends React.Component {
    //const LoginContainer = () => (
    //    <div className="container">
    //        <Route exact path="/" render={() => <Redirect to="/login" />} />
    //        <Route path="/login" component={Login} />
    //    </div>
    //)

    //const DefaultContainer = () => (
    //    <div>
    //        <div className="container">
    //            <Navbar />
    //            <PrivateRoute exact path="/dashboard" component={Dashboard} />
    //            <PrivateRoute exact path="/zaposleni" component={EmployeeList} />
    //        </div>
    //    </div>
    //)

    componentWillMount() {
        const self = this

        axios.defaults.baseURL = (process.env.NODE_ENV !== 'production') ? 'http://localhost:5000/api' : 'https://placeapi.ezahtevek.eu/api';
        //axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
        //axios.defaults.headers.post['Content-Type'] = 'application/json';

        axios.interceptors.request.use(function (config) {
            // spinning start to show
            self.props.loadActions(true)
            return config
        }, function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            // spinning hide
            self.props.loadActions(false)

            return response;
        }, function (error) {
            self.props.loadActions(false)

            return Promise.reject(error);
        });
    }

    //todo jozeb: register obrazec je po defaultu skrit
    //<Route exact path="/register" component={Register} />
    render() {
        return (
            <div className="container">
                <Router>
                    <Route exact path="/" component={Landing} />              
                    <Route exact path="/login" component={Login} />
                    
                    {
                    this.props.load ? <Loader
                        className="centered"
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        //timeout={1}
                    /> : null
                    }

                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/zaposleni" component={EmployeeListPagination} />
                    <PrivateRoute exact path="/obracunplac" component={PaymentProcess} />
                    <PrivateRoute exact path="/zaposleni/edit/:id" component={EmployeeEdit} />
                    <PrivateRoute exact path="/zaposleni/:id" component={EmployeeEdit} />
                    <PrivateRoute exact path="/zaposleni/ure/:id" component={EmployeeWorkingHours} />
                    <PrivateRoute exact path="/zaposleni/akontacije/:id" component={EmployeeAdvancePayments} />
                    <PrivateRoute exact path="/uvoz" component={EmployeeWorkingHoursImport} />
                    <PrivateRoute exact path="/report" component={EmployeeReportParams} />
                    <PrivateRoute exact path="/sepa/edit/:id" component={SepaPayment} />
                    <PrivateRoute exact path="/sepa/:id" component={SepaPayment} />
                    <PrivateRoute exact path="/sepa" component={SepaPaymentPagination} />
                    <PrivateRoute exact path="/separams" component={SepaPaymentParams} />
                   
                </Router>
            </div>
            
        );
    }
}

//<PrivateRoute path="/zaposleni/edit/:newitem" component={EmployeeEdit} />

const mapStateToProps = (state) => {
    return {
        load: state.load
    }
}

export default connect(
    mapStateToProps, {
        loadActions
    }
)(App);
