import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import axios from 'axios';

class SepaPaymentPagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            selected: [],
            columns: [
            {
                dataField: 'receivername',
                text: 'Naziv',
                sort: true
            },
            {
                dataField: 'iban',
                text: 'IBAN',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '230px', textAlign: 'center' };
                }
            },
            {
                dataField: 'swift',
                text: 'SWIFT',
                sort: true
            },
            {
                dataField: 'amount',
                text: 'Znesek',
                sort: true,
                formatter: (cellContent, row) => {
                    return (
                        <div>{cellContent} {row.currency}</div>
                    );
                }
            },
            {
            dataField: 'purpuse',
            text: 'Namen',
            sort: true
            },
                {
                    dataField: 'df1',
                    isDummyField: true,
                    text: 'Akcije',
                    formatter: (cellContent, row) => {
                        if (true) {
                            return (
                                <nav>
                                    <Link to={{
                                        pathname: "/sepa/edit/" + row._id
                                        //currentSepa: row.firstname + " " + row.lastname
                                    }}><button className="btn btn-outline-secondary btn-sm" type="button">Uredi</button></Link>
                                    <button className="btn btn-outline-secondary btn-sm" onClick={() => this.handleBtnDeleteSepa(row._id)} type="button">Izbriši</button>
                                </nav>
                            );
                        }
                        return (
                            <h5>
                                <span className="label label-danger"> Backordered</span>
                            </h5>
                        );
                    }
                }
            ]
        };
    }

    componentDidMount() {
        axios.get('/sepagen/')
            .then(response => {
                this.setState({ payments: response.data })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected : [...this.state.selected, row._id]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row._id)
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r._id);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
        }
    }

    handleBtnPridobiXML = () => {
        let selection = this.state.selected;

        if (selection.length === 0) {
            alert("Izberite vsaj en plačilni nalog!");
            return true;
        }

        this.props.history.push("/separams", { selectedSepas: selection });
    }

    handleBtnDodajSepa = () => {
        this.props.history.push("/sepa/new");
    }

    handleBtnIzbrisiVse = () => {

        // eslint-disable-next-line no-restricted-globals
        if (confirm("Ali ste prepričani, da želite izbrisati vse plačilne naloge?") == true) {
            axios.delete('/sepagen/-1')
                .then(res => {
                    console.log(res.data)

                    this.setState({
                        payments: '' //brisanje vseh
                    })
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            //text = "You canceled!";
        }

        
    }

    handleBtnDeleteSepa = (id) => {
        axios.delete('/sepagen/' + id)
            .then(res => {
                console.log(res.data)

                this.setState({
                    payments: this.state.payments.filter(item => item._id !== id)      //brisanje objekta iz array-a
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
            style: { backgroundColor: '#adcdff' }
        };

        return (
            <div className="container" style={{ marginTop: 50 }}>
                <ToolkitProvider
                    bootstrap4={true}
                    keyField="_id"
                    data={this.state.payments}
                    columns={this.state.columns}
                    >
                    {
                        props => (
                            <div>
                                <div className="row">
                                    <div className="btn-block">
                                        <button onClick={this.handleBtnPridobiXML} className="btn btn-primary float-right btn-space">Pridobi SEPA XML</button>
                                        <button onClick={this.handleBtnDodajSepa} className="btn btn-primary float-right btn-space">Dodaj</button>
                                        <button onClick={this.handleBtnIzbrisiVse} className="btn btn-primary float-right btn-space">Izbriši vse</button>
                                    </div>
                                </div>

                                <hr />

                                <BootstrapTable
                                    pagination={paginationFactory()}
                                    selectRow={selectRow}
                                    headerClasses="thead-light"
                                    {   
                                        ...props.baseProps
                                    }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
            
        );
    }
}

export default connect(
)(SepaPaymentPagination);


