import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
//import logo from '../../../public/is_logo.png'

class Navbar extends Component {
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    //<a className="navbar-brand" href="/"><span role="img" aria-label="money">💰 Plače</span></a>

    render() {
        return (
            <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
                <a className="navbar-brand" href="/">
                    <img src="/is_logo.png" width="70%" alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="nav navbar-nav ml-auto">
                        <li className="nav-item ">
                            <Link to={'/dashboard'} className="nav-link">Domov</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/zaposleni'} className="nav-link">Zaposleni</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/zaposleni/new'} className="nav-link">Dodaj zaposlenega</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/sepa'} className="nav-link">Plačilni nalogi</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/uvoz'} className="nav-link">Uvoz</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'/'} onClick={this.onLogoutClick} className="nav-link">Odjava</Link>
                        </li>
                    </ul>
                </div>
            </nav>

            //<li className="nav-item dropdown">
            //    <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
            //        Dropdown link
            //    </a>
            //    <div className="dropdown-menu">
            //        <a className="dropdown-item" href="#">Link 1</a>
            //        <a className="dropdown-item" href="#">Link 2</a>
            //        <a className="dropdown-item" href="#">Link 3</a>
            //    </div>
            //</li>

            //<nav className="navbar navbar-dark bg-dark navbar-expand-lg">
            //    <Link to="/" className="navbar-brand">💰 Plače</Link>
            //    <div className="collpase navbar-collapse">
            //        <ul className="navbar-nav mr-auto">
            //            <li className="navbar-item">
            //                <Link to={'/dashboard'} className="nav-link">Domov</Link>
            //            </li>
            //            <li className="navbar-item">
            //                <Link to={'/zaposleni'} className="nav-link">Zaposleni</Link>
            //            </li>
            //            <li className="navbar-item">
            //                <Link to={'/zaposleni/new'} className="nav-link">Dodaj zaposlenega</Link>
            //            </li>
            //            <li className="navbar-item">
            //                <button
            //                    onClick={this.onLogoutClick}
            //                    className="btn btn-primary">
            //                    Odjava
            //                </button>
            //            </li>
            //        </ul>
            //    </div>
            //</nav>

                //<div className="navbar-fixed">
                //    <nav className="z-depth-0">
                //        <div className="nav-wrapper blue">
                //            <ul className="navbar-nav mr-auto">
                //                <li><Link to={'/dashboard'} className="nav-link">Domov</Link></li>
                //                <li><Link to={'/delavci'} className="nav-link">Zaposleni</Link></li>
                //                <li><Link to={'/about'} className="nav-link">About</Link></li>
                //            </ul>

                //            <div className="col s5 brand-logo center black-text">
                //                Plače v1, pozdravljen {user.name}
                //            </div>

                //            <button
                //                onClick={this.onLogoutClick}
                //                className="btn btn-medium waves-effect waves-light hoverable blue accent-3">
                //                Logout
                //    </button>
                //        </div>
                //    </nav>

                //</div>
        );
    }
}

//Navbar.propTypes = {
//    logoutUser: PropTypes.func.isRequired,
//    auth: PropTypes.object.isRequired
//};

const mapStateToProps = state => ({
    auth: state.auth
});

//export default Navbar;
export default connect(
    mapStateToProps,
    { logoutUser }
)(Navbar);