const initialState = {
    paymentUsers: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_PAYMENT_USERS":   //nastavi objekt izbranih uporabnikov, da jih lahko naprej obdelujemo 


            return action.payload;
        default:
            return state;
    }
}