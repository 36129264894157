import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import axios from 'axios';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const monthNames = ["Januar", "Februar", "Marec", "April", "Maj", "Junij",
    "Julij", "Avgust", "September", "Oktober", "November", "December"
];

class EmployeeWorkingHours extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addWorkingHour = this.addWorkingHour.bind(this);

        this.state = {
            hours: [],
            selected: [],
            newData: {
                month: '',
                quantity: '',
                rate: ''
            },
            columns: [
                //{
                //    dataField: '_id',
                //    text: 'ID',
                //    sort: true
                //    },
                {
                    dataField: 'month',
                    text: 'Mesec',
                    sort: true,
                    formatter: (cell) => {
                        if (cell == null)
                            return

                        let dateObj = new Date(cell);
                        return monthNames[dateObj.getMonth()] + " " + dateObj.getFullYear();
                    }
                },
                {
                    dataField: 'quantity',
                    text: 'Količina',
                    sort: true
                }, {
                    dataField: 'rate',
                    text: 'Urna postavka',
                    sort: true
                }, {
                    dataField: 'df1',
                    isDummyField: true,
                    text: 'Akcije',
                    formatter: (cellContent, row) => {
                        return (
                            <button className="btn btn-outline-secondary btn-sm" type="button" onClick={() => this.deleteWorkingHour(row._id)}>Izbriši</button>
                        );
                    }
                }


            ]
        };
    }
    //
    componentDidMount() {
        axios.get('/employee/getHours/' + this.props.match.params.id)
            .then(response => {
                this.setState({ hours: response.data.workingHours })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    addWorkingHour(e) {
        e.preventDefault();

        const workingHours = this.state.newData;
       
        axios.post('/employee/addHours/' + this.props.match.params.id, workingHours)
            .then(res => {
                console.log(res.data)
                this.setState({ hours: [...this.state.hours, res.data], newData: {} })   //kompakten način dodajanja elementa v array objektov
                //this.props.history.push("/zaposleni");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    deleteWorkingHour(id) {
        const employeeId = {
            employeeId: this.props.match.params.id
        };

        //pri delete-u se pošiljajo parametri na drugačen način
        axios.delete('/employee/deleteHours/' + id, { data: employeeId })
            .then(res => {
                console.log(res.data)

                this.setState({
                    hours: this.state.hours.filter(item => item._id !== id)      //brisanje objekta iz array-a
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ newData: { ...this.state.newData, [name]: value } });
    }

    render() {
        return (
            <div className="container" style={{ marginTop: 20 }}>
                <h3>Ure zaposlenega - {this.props.location.currentEmployee || ''}</h3>
                <br/>

                <form onSubmit={this.addWorkingHour}>
                    <div className="input-group mb-3">
                        <input type="month" className="form-control" placeholder="Mesec" required name="month" value={this.state.newData.month || ''} onChange={this.handleInputChange} />
                        <input type="text" className="form-control" placeholder="Količina" required name="quantity" value={this.state.newData.quantity || ''} onChange={this.handleInputChange} />
                        <input type="text" className="form-control" placeholder="Urna postavka" required name="rate" value={this.state.newData.rate || ''} onChange={this.handleInputChange} />

                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="submit">Dodaj</button>
                        </div>
                    </div>
                </form>

                <ToolkitProvider
                    keyField="_id"
                    data={this.state.hours}
                    columns={this.state.columns}>
                    {
                        props => (
                            <div>

                                <BootstrapTable
                                    bootstrap4={true}
                                    pagination={paginationFactory()}
                                    headerClasses="thead-light"
                                    {
                                    ...props.baseProps
                                    }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
    }
}

export default connect(
)(EmployeeWorkingHours);


