import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';

class EmployeeReportParams extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projectname: "",
            projectdate: "",
            selectedEmployees: []
        }
    }

    componentDidMount() {
        if(this.props.location.state)
            this.setState({ selectedEmployees: this.props.location.state.selectedEmployees });  
    }

    handleInputChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let xhr = new XMLHttpRequest()
        xhr.open('POST', axios.defaults.baseURL + "/reports/", true)
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
        xhr.responseType = 'arraybuffer'

        xhr.onload = function (e) {
            if (this.status === 200) {
                let blob = new Blob([this.response], { type: "application/pdf" })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Results.pdf'
                link.click()
            }
        };

        xhr.send("data=" + JSON.stringify(this.state));

    }

    handleBtnCancelClick = () => {
        this.props.history.push("/zaposleni")
    }

    render() {
        const { projectname, projectdate} = this.state
        return (
            <div>
                <br />
                <h3>Vnesite parametre poročila</h3>
                <br />

                <form onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputProjectname">Ime projekta</label>
                            <input name="projectname" value={projectname || ''} required type="text" className="form-control" id="inputProjectname" placeholder="Ime projekta" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="inputDateofproject">Datum</label>
                            <input name="projectdate" type="date" required className="form-control" id="inputDateofproject" value={projectdate || ''} onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <hr />
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-space">Pridobi</button>
                        <button onClick={this.handleBtnCancelClick} className="btn btn-primary">Nazaj</button>
                    </div>
                </form>

            </div>
        );

    }
}

export default connect(
)(EmployeeReportParams);


